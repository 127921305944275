:root {
    --vh: 1vh; // TODO: Create a logic to set this to a more fixed value to preven jumping

    // Spacings
    --spacing: 20px;
    --spacing--small: 15px;
    --spacing--large: 40px;
    --margin: 22px;
    --gutter: 12px;

    // Colors
    --color-dark: #{shade(#abcce2, 10%)};
    --color-promoted: #abcce2;
    --color-background: #{tint(#2960f6, 85%)};
    --color-light: #{tint(#2960f6, 90%)};

    // Other stuff
    --header-height: 90px;
    --column-width: 13rem;
    --section-spacing: max(14vh, 7rem);
    --section-spacing--small: 3rem;
    --section-spacing-medium: 5rem;

    --circle-size: 38px;
    --logo-height: 47px;

    @include min-width(md) {
        --spacing: #{pxToRem(44px)};
        --spacing--small: #{pxToRem(20px)};
        --spacing--large: #{pxToRem(68px)};
        --margin: #{var(--spacing)};
        --gutter: #{var(--spacing--small)};

        --column-width: 11rem;
        --header-height: 110px;
        --section-spacing: max(26vh, 10rem);
        --section-spacing--small: 4rem;
        --section-spacing-medium: 8rem;
    }

    @include min-width(lg) {
        --gutter: #{var(--spacing)};
    }

    @include min-width(xxl) {
        --spacing: #{pxToRem(48px)};
        --column-width: 14.5rem;
        --margin: #{var(--spacing--large)};
    }

    $large-breakpoint: map-get($breakpoints, md);

    @media screen and (min-width: $large-breakpoint) and (max-height: $height-breakpoint) {
        --margin: 2rem;
    }
}
