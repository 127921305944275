@import 'styles/helpers';

@layer defaults {
    .icon {
        $this: &;
        display: inline-block;

        &__line {
            fill: none;
            stroke: currentColor;
            stroke-width: $icon-stroke;

            &--fill {
                fill: currentColor;
            }

            &--thin {
                stroke-width: 1.5;
            }

            &--muted {
                stroke-opacity: 0.08;
            }
        }

        &__fill {
            fill: currentColor;
        }

        &--left {
            transform: rotate(-180deg);
        }

        &--up {
            transform: rotate(-90deg);
        }

        &--down {
            transform: rotate(90deg);
        }
    }
}
