@import 'styles/_helpers';

.list {
    $this: &;
    $indention: 1em;

    &:not(:last-child) {
        // margin-bottom: $spacing;
        margin-bottom: $spacing;
    }

    &__item {
        counter-increment: list;
        position: relative;
        padding-left: $indention;
        margin-bottom: 0.7em;
    }

    &--normal {
        #{$this}__item {
            position: relative;

            &:before {
                $size: 7px;
                content: '';
                position: absolute;
                width: $size;
                height: $size;
                left: 1px;
                top: 0.5em;
                border-radius: 50%;
                background-color: currentColor;
            }
        }
    }

    &--numbered {
        counter-reset: list;

        #{$this}__item {
            &:before {
                content: counter(list) '.';
                margin-left: -($indention);
                position: relative;
                display: inline-block;
                width: $indention;
                top: 0;
            }
        }
    }
}
