@font-face {
    font-family: 'Unica77';
    src: url('/fonts/unica77-regular.woff') format('woff'),
        url('/fonts/unica77-regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unica77';
    src: url('/fonts/unica77-bold.woff') format('woff'),
        url('/fonts/unica77-bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans';
    src: url('/fonts/NotoSansSC-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans';
    src: url('/fonts/NotoSansSC-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
