body {
    // Type
    @include font(base, normal);
    @include font-size(base);
    position: relative;

    color: $color-text;
    font-style: normal;
    // font-variant-numeric: tabular-nums;
    overflow-x: hidden;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: contextual;
    text-rendering: optimizeLegibility;
    // -webkit-font-variant-ligatures: contextual;
    // -webkit-text-rendering: optimizeLegibility;

    background-color: $color-background;

    &.block-scrolling {
        overflow: hidden;
        overscroll-behavior: contain;
    }

    .history {
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        height: -webkit-fill-available;
        height: calc(var(--vh) * 100);
        touch-action: none;
    }
}

html:has(body.history) {
    position: static;
    overflow: hidden;
    height: 100%;
    height: -webkit-fill-available;
    height: calc(var(--vh) * 100);
    max-height: 100%;
    max-height: -webkit-fill-available;
    max-height: calc(var(--vh) * 100);
}
