html {
    font-size: #{$font-size-base}px;
    // scroll-snap-type: y proximity;
    // scroll-padding: 100px 0 0;

    $large-breakpoint: map-get($breakpoints, lg);

    @media screen and (min-width: $large-breakpoint) and (max-height: $height-breakpoint) {
        font-size: #{$font-size-base--xs}px;
    }
}
