a {
    cursor: pointer;

    color: inherit;

    &:not([class]) {
        @include underline;
    }

    &:hover,
    &:focus {
        color: inherit;
    }
}
