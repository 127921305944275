@import 'styles/_helpers';

.layout {
    $this: &;
    $logo-height: var(--logo-height);
    $menu-size: 44px;

    position: relative;
    min-height: 100vh;
    max-width: 90rem;

    @include min-width(md) {
        display: grid;
        grid-template-columns: var(--column-width) auto;
        grid-template-areas:
            'marg content'
            'footer footer';
        margin-left: auto;
        margin-right: auto;
    }

    &__logo {
        $optical-aligning: 0.13rem;
        position: sticky;
        z-index: $level-3;
        top: calc(100vh - #{$logo-height} - #{$margin} + #{$optical-aligning});
        align-items: flex-end;
        height: $logo-height;
        margin-left: $margin;

        display: none;

        @include min-width(md) {
            display: flex;
            bottom: calc(#{$margin} - 0.075em);
        }
    }

    &__marg {
        grid-area: marg;
    }

    &__content {
        grid-area: content;
        position: relative;
        min-height: 100vh;
        padding-bottom: $section-spacing;
    }

    &__wrapper {
        position: relative;
    }

    &__page {
        position: relative;
        padding: $margin;
    }

    &__footer {
        grid-area: footer;
        position: relative;
        padding-left: $margin;
        padding-right: $margin;
        padding-bottom: calc(#{$margin} * 2 + #{$header-height});
        background-color: $color-background;

        @include min-width(md) {
            grid-template-columns: 7fr 4fr;
            align-items: start;
            padding-bottom: calc(#{$margin} * 2);

            margin-top: calc(var(--logo-height) * -1);
        }

        @include min-width(md) {
            &--show {
                &:before {
                    $height: 50px;
                    content: '';
                    position: absolute;
                    top: -($height - 1);
                    height: $height;
                    width: 100%;
                    background-image: linear-gradient(rgba(white, 0), rgba(white, 1));

                    animation: fade-in 500ms linear $ease-out-quart;
                }

                #{$this}__tramp-stamp {
                    opacity: 1;
                    animation: footer-item-enter 500ms $ease-out-quart both;
                    animation-delay: 500ms;
                }

                #{$this}__link {
                    opacity: 1;
                    animation: footer-item-enter 500ms $ease-out-quart both;
                    @include animation-delay(4, 50ms, 250ms);
                }
            }
        }

        &--hidden {
            display: none !important;
        }
    }

    &__what {
        @include font-size(title);
        position: relative;
        background-color: $color-light;
        max-width: 36rem;

        @include min-width(md) {
            opacity: 0;
            clip-path: inset(0 100% 80% 0);
            transition: clip-path 300ms $ease-out-quart, opacity 200ms $ease-out-quart;

            &--show {
                z-index: $level-3;
                opacity: 1;
                clip-path: inset(0 0 0 0);
                transition: clip-path 600ms $ease-out-quart;
                transition-delay: 120ms;
            }
        }
    }

    &__tramp-stamp,
    &__link {
        @include min-width(md) {
            opacity: 0;
        }
    }

    &__tramp-stamp {
        color: $color-muted;
        padding-top: $spacing;
        margin-bottom: $spacing;
        background-color: $color-light;
    }

    &__links {
        display: flex;
        flex-direction: column;
        padding-top: $spacing;
    }

    &__link {
        @include font-size(sub-title);
        margin-bottom: 0.3em;
    }

    &--full {
        #{$this}__content {
            padding-bottom: 0;
        }
    }
}

@keyframes footer-item-enter {
    from {
        clip-path: inset(0 100% 100% 0 round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round 0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
