@import 'styles/_helpers';

.brand {
    $this: &;
    @include font(base, bold);
    @include font-size(title);
    display: flex;
    align-items: baseline;

    &:after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-left: 2px;
        background-color: color(primary, dark);

        @include min-width(lg) {
            width: 6px;
            height: 6px;
        }
    }
}
