@import 'styles/_helpers';

.styles {
    $this: &;
    $toggle: 27px;
    position: relative;

    &__toggle {
        position: relative;
        display: inline-flex;
        vertical-align: baseline;
        align-items: center;
        justify-content: center;
        padding: 2px 12px;
        margin-top: -2px;
        margin-bottom: -2px;
        border-radius: $border-radius--small;
        background-color: color(primary, light);
        box-shadow: 0 0 0 $border-width $color-background;
    }

    &__content {
        $width: 400px;
        @include font-size(base);
        z-index: $level-4;
        bottom: 40px;
        border-radius: 14px;
        width: $width;
        max-width: 60vw;
        padding: $spacing;
        background-color: color(primary, light);
        will-change: clip-path;
        transform-origin: var(--radix-popover-content-transform-origin);
        animation-duration: 500ms;
        animation-timing-function: $ease-out-quart;
        animation-fill-mode: both;
        &[data-state='open'] {
            &[data-side='bottom'] {
                animation-name: inline-ref-bottom;
            }
            &[data-side='top'] {
                animation-name: inline-ref-top;
            }
        }

        blockquote, figcaption {
            display: inline;
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $level-3;
        opacity: 0.6;
        background-color: $color-background;
        animation: fade-in 500ms $ease-out-quart both;
    }

    &--expanded {
        #{$this}__toggle {
            z-index: $level-4;
        }
    }

}

@keyframes inline-ref-bottom {
    from {
        clip-path: inset(0 0 90% 90% round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }
}

@keyframes inline-ref-top {
    from {
        clip-path: inset(90% 0 0 90% round #{$border-radius});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius});
    }
}