@import 'styles/_helpers';

.menu {
    $this: &;
    $menu-size: 44px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: calc(#{$margin} - 5px) $margin 0;
    gap: 5px;
    z-index: $level-3;
    transition:
        opacity 500ms $ease-out-quart,
        transform 500ms $ease-out-quart;
    transition-delay: 200ms;

    @include min-width(md) {
        position: sticky;
        top: calc(100vh - var(--logo-height) * 2 - #{$margin} - 0.1rem);
        align-items: flex-end;
        height: var(--logo-height);
        width: calc(var(--column-width) - #{$margin});
        margin-left: $margin;
        bottom: calc(#{$margin} - 0.075em);
        padding: 0 0 $spacing--small;
        overflow: visible;
    }

    &--hidden {
        opacity: 0;
        transform: translateY(100%);
        pointer-events: none;
        transition:
            opacity 300ms $ease-out-quart,
            transform 500ms $ease-out-quart;
        transition-delay: 0ms;
    }

    &__brand {
        display: block;
        margin-right: auto;

        @include min-width(md) {
            display: none;
        }
    }

    &__toggle {
        order: 3;
        position: relative;
        z-index: 2;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: $menu-size;
        height: $menu-size;
        border-radius: $border-radius--small;
        background-color: color(neutral, light);

        &:hover,
        &:focus:focus-visible {
            background-color: color(neutral);
        }

        @include min-width(md) {
            order: 0;
        }
    }

    &__menu-icon {
        fill: none;
    }

    &__menu-line {
        stroke: currentColor;
        stroke-width: 1.3;
    }

    &__search {
        position: relative;

        transition:
            opacity 320ms $ease-in-quart,
            transform 600ms $ease-out-quart;
        transition-delay: 500ms;

        order: 2;

        @include min-width(md) {
            order: 0;
        }

        &--hidden {
            z-index: -1;
            transform: translateX(100%);
            opacity: 0;
            transition:
                opacity 320ms $ease-out-quart,
                transform 600ms $ease-out-quart;
            transition-delay: 0;

            @include min-width(md) {
                transform: translateX(-50%);
            }
        }

        &--small {
            @include max-width(md) {
                transition:
                    width 500ms $ease-out-back--subtle,
                    opacity 320ms $ease-out-quart,
                    transform 400ms $ease-out-quart;
                width: 10rem;

                &:not(:focus-within) {
                    width: $menu-size;
                }

                #{$this}__submit {
                    left: 0;
                    width: $menu-size;
                }
            }
        }

        &--large {
            #{$this}__input {
                font: inherit;
                height: auto;
                width: 5.6em;
                padding: 0.4em 1em 0.4em 1.7em;
                border-radius: 0.6em;
                transition: width 500ms $ease-out-back--subtle;
                max-width: 100%;

                &:focus {
                    width: 10em;
                    border-color: color(neutral, light);
                }
            }

            #{$this}__icon {
                width: 0.8em;
            }

            #{$this}__submit {
                left: 0.64em;
            }
        }
    }

    &__input {
        @include font(base, normal);
        @include font-size(small);
        width: 100%;
        max-width: 100%;
        padding: 0;
        padding-left: $menu-size;
        height: $menu-size;
        border: $border-width solid color(neutral, light);
        border-radius: $border-radius--small;
        background-color: color(neutral, light);

        @include min-width(md) {
            padding-left: 35px;
        }

        &:focus {
            border-color: color(neutral);
        }
    }

    &__submit {
        pointer-events: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        left: 13px;
        width: auto;
    }

    &__menu {
        position: fixed;
        z-index: $level-4;
        top: calc((#{$margin} * 2) + var(--logo-height));
        left: 0;
        width: 100%;
        @include overflow-scroll-y;

        @include min-width(md) {
            top: auto;
            bottom: calc((#{$margin} * 2) + var(--logo-height) + #{$spacing--small});
        }

        @include min-width(xxl) {
            bottom: calc((#{$margin} * 2) + var(--logo-height));
        }
    }

    &__content {
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.2em;
        margin: 0 auto;
        width: 100%;
        max-width: 90rem;
        padding: 0 $margin;
        padding-bottom: $spacing--small;

        font-size: calc(1.2vw + 5lvh / 2);
        line-height: 1.3;

        @include min-width(md) {
            align-items: flex-start;
        }
    }

    &__item {
        padding: 0.4em 1em;
        border-radius: 0.6em;
        background-color: color(primary, light);

        &:hover,
        &:focus:focus-visible {
            background-color: color(primary);
        }

        &--topic {
            font-size: 0.8em;

            @include min-width(md) {
                font-size: 0.6em;
            }
        }
    }

    :global([data-accordion]),
    &__item,
    &__search--large {
        @include entering {
            animation: item-enter 500ms $ease-in-out-quint--custom both;
            @include animation-delay(10, 80ms, 0);

            @include min-width(md) {
                @include animation-delay-reversed(10, 80ms, 0);
            }
        }

        @include exiting {
            animation: item-exit 500ms $ease-in-out-quint--custom forwards;
            @include animation-delay(10, 60ms, 0);
        }
    }

    &__topics {
        display: flex;
        flex-wrap: wrap;
        gap: 0.2em;

        @include max-width(md) {
            justify-content: end;
        }
    }

    &__backdrop {
        position: fixed;
        z-index: $level-3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-light, 0.99);

        @include entering {
            animation: fade-in 500ms $ease-out-quart backwards;
        }

        @include exiting {
            animation: fade-out 700ms $ease-in-quart forwards;
        }
    }
}

@keyframes item-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes item-exit {
    from {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(100% 0 0 100% round #{$border-radius--small});
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}
