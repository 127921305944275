h1,
h2,
h3 {
    // @include hyphens;
    @include font(base, bold);
    margin: 0;
}

h1 {
    @include font-size(title);
}

h2 {
    @include font-size(sub-title);
}

h3 {
    @include font-size(base);
}

// Paragraph
p {
    @include font(base, normal);
    margin: 0;
}


// Strong / Bold
strong,
b {
    @include font(base, bold);
}
