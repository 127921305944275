@import 'styles/_helpers';

.quote {
    $this: &;

    border-left: $border-style;
    padding-left: $indention;

    &:not(:last-child) {
        margin-bottom: $spacing;
    }
}
