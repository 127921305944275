@use 'sass:math';
@import 'styles/_helpers';

.accordion {
    $this: &;
    $duration: 400ms;
    $padding-y: 18px;
    $padding-x: 26px;
    @include font-size(base);
    width: 100%;
    border-radius: $border-radius;
    background-color: color(primary, light);

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &__toggle {
        @include font(base, normal);
        // @include uppercase;
        position: relative;
        text-align: left;
        width: 100%;
        border-radius: inherit;
        padding: $padding-y $padding-x;
    }

    &__indicator {
        $size: 20px;
        @include font(base, normal);
        position: absolute;
        right: $padding-x;
        top: calc(50% - #{math.div($size, 2)});
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;
        transition: transform $duration $ease-in-out-quint--custom;
    }

    &__title {
        font: inherit;
        margin: 0;
    }

    &__wrapper {
        overflow: hidden;
        outline: 0;
        transition: max-height $duration $ease-in-out-quint--custom;

        &--expanded {
            overflow: visible;
        }
    }

    &__content {
        padding: 0 $padding-x $padding-y;
        outline: 0;

        p:not(:last-child) {
            margin-bottom: 1em * $base-line-height;
        }
    }

    &--expanded {
        #{$this}__indicator {
            transform: rotate(-180deg);
        }

        #{$this}__wrapper {
            will-change: max-height;
        }
    }

    &--collapsed {
        &:hover {
            background-color: color(primary);
        }
    }

    &--minimal {
        border-radius: 0;
        background-color: transparent;

        #{$this}__toggle {
            height: 1em * 1.4;
            width: auto;
            color: $color-muted;
            padding: 0 22px 0 0;
            text-transform: none;
        }

        #{$this}__indicator {
            right: 0;
        }

        #{$this}__content {
            padding: 0;
        }

        &#{$this}--collapsed:hover {
            background-color: transparent;
        }
    }

    &--menu {
        border-radius: 0;
        background-color: transparent;

        animation: item-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);

        &:not(:last-child) {
            margin-bottom: 0.3em;
        }

        #{$this}__toggle {
            height: auto;
            width: auto;
            text-transform: none;

            @include font-size(small);
            color: $color-text;
            padding: 8px 12px;
            padding-right: 28px;
            border-radius: $border-radius--small;
            background-color: color(neutral, xlight);

            &:hover,
            &:focus:focus-visible {
                background-color: color(neutral, light);
            }
        }

        #{$this}__indicator {
            right: 4px;
        }

        #{$this}__content {
            padding: 0.3em 0;
        }

        &#{$this}--collapsed:hover {
            background-color: transparent;
        }
    }

    &--xxl {
        @include max-width(md) {
            display: flex;
            flex-direction: column;
            align-items: end;
        }

        font: inherit;
        font-size: inherit;
        line-height: inherit;
        border-radius: 0;
        background-color: transparent;

        animation: item-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);

        &:not(:last-child) {
            margin-bottom: 0;
        }

        #{$this}__toggle {
            font: inherit;
            font-size: inherit;
            line-height: inherit;
            height: auto;
            width: fit-content;
            text-transform: none;
            color: $color-text;

            padding: 0.4em 1em;
            padding-right: 1.8em;
            border-radius: 0.6em;
            background-color: color(primary, light);

            &:hover,
            &:focus:focus-visible {
                background-color: color(primary);
            }
        }

        #{$this}__indicator {
            right: 0.8em;
        }

        #{$this}__icon {
            @include min-width(md) {
                width: 0.3em;
            }
        }

        #{$this}__content {
            padding: 0.3em 0;
        }

        &#{$this}--collapsed:hover {
            background-color: transparent;
        }
    }
}

@keyframes item-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round 0);
    }
}
